import React from 'react'
import './AreaChart1.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


export default function AreaChart1(props) {
    // =------------Starting the Graph Data Here
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                // position: 'top' as const,
                position: 'top',
            },
            // title: {
            //     display: true,
            //     text: 'Temperature & Humidity Data',
            // },
        },
        scales: {
            y: {
                beginAtZero: true,
                // max: 100
            }
        }

    };

    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    // const labels = stats.slice(-24).map((timedata) => timedata?.time)
    const labels = props.graphTime
    // const labelTemp = 'Temperature\t' + meterDashData[meterDashData.length - 1]?.value?.temp + '°C '
    // const labelHumid = 'Humidity\t' + meterDashData[meterDashData.length - 1]?.value?.humidity + '% \t \t \t \t \t \t \t \t' + meterDashData[meterDashData.length - 1]?.time +'\t | \t'  + meterDashData[meterDashData.length - 1]?.date  + ' \t \t \t \t \t \t \t \t ' + meterDashData[meterDashData.length - 1]?.value?.Device_ID
    const data = {
        labels,
        datasets: [
            {
                fill: false,
                label: "ETP Onlet-1",
                data: props.flowData,
                borderColor: 'rgb(41, 128, 185 )',
                backgroundColor: 'rgba(169, 204, 227, 0.7)',
                borderJoinStyle: 'round',
            },
            {
                fill: false,
                label: "ETP Onlet-2",
                data: props.flowData1,
                borderColor: 'rgb(0, 128,0 )',
                backgroundColor: 'rgba(0, 204, 0, 0.7)',
                borderJoinStyle: 'round',
            },
            {
                fill: false,
                label: "ETP Outlet",
                data: props.flowData2,
                borderColor: 'rgb(169, 0, 227 )',
                backgroundColor: 'rgba(169, 0, 227, 0.7)',
                borderJoinStyle: 'round',
            },
            {
                fill: false,
                label: "ETP Waste",
                data: props.flowData3,
                borderColor: 'rgb(41, 0, 0 )',
                backgroundColor: 'rgba(169, 0, 0, 0.7)',
                borderJoinStyle: 'round',
            },
        ],
    };
  return (
    <>
    <Line className='linechartt' options={options} data={data} />
    </>
  )
}
