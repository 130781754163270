import React from "react";
import "./Footer.css";
import hunch from "../../../Asserts/Logo/hunch.png";

export default function Footer(props) {
  return (
    <>
      <div className="footer">
        <div className="btns">
          {/* <GoBack />
          <Logout /> */}
        </div>
        <div className="develop">
          <div className="develophead">Develop & Design by HUNCH Automation Private Limited www.hunch.com.pk</div>
          <img className="hunchlogo" src={hunch} alt="Uploading" />
        </div>
      </div>
    </>
  );
}
