import React from 'react'
import './ReportMachine.css'
import Header from '../Common/Header/Header'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import jsPDF from "jspdf";
import { useNavigate, useLocation } from 'react-router-dom';

const ReportMachine = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const date = location.state.date
  let array = location?.state?.filterDate
  let ar0 = array[0]?.meterReadingsHalfHour
  let ar1 = array[1]?.meterReadingsHalfHour
  let ar2 = array[2]?.meterReadingsHalfHour
  let ar3 = array[3]?.meterReadingsHalfHour
  let arr = [].concat(ar0).reverse()
  let arr1 = [].concat(ar1).reverse()
  let arr2 = [].concat(ar2).reverse()
  let arr3 = [].concat(ar3).reverse()
  let flowtotal0 = ar0[ar0.length-1]?.total - ar0[0]?.total
  let flowtotal1 = ar1[ar1.length-1]?.total - ar1[0]?.total 
  let flowtotal2 = ar2[ar2.length-1]?.total - ar2[0]?.total
  let flowtotal3 = ar3[ar3.length-1]?.total - ar3[0]?.total
  console.log(ar0)

  const reportformat = "GulAhmed\t" + date.startDate + "\t\t" + date.endDate
  // console.log("The Format of the report is:------",reportformat)
  // console.log("start date is-----",date.startDate,"end date is :-----", date.endDate)
  const pdfbtn = () => {
    var doc = new jsPDF('l', 'pt');
    var content = document.getElementById("reportid");
    doc.html(content, {
      callback: function (doc) {
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(12)
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          // doc.rect(20, 20, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 40, 'S');
          doc.text('Page' + String(i) + ' of ' + String(pageCount), 842 - 30, 595 - 30, null, null, "right")
        }
        doc.save(reportformat);
      }
    })

  }
  const close = () => {
    navigate(-1)
  }


  return (
    <>
      <div className="reportmain">
        <div className="report" id='reportid'>
          {/* <div className="reportmaintable"> */}
          <Header head={"MRS9000 GAS FLOW Monitoring With Reporting"} />
          <div className="date">{date.startDate} ~ {date.endDate}</div>
          <div className="alltables">
            {/* Table for Inlet-1 */}
            <table className='tablemain' id='tablee'>
              <thead className='tablehead'>
                <tr className='tablerow'>
                  <th className='tableh'>Time | Date</th>
                  <th className='tableh flowtotalid'>{array[0]?.machineName} <br /> Flow m3/m | Total m3 <br />{array[0]?.Device_ID}</th>
                </tr>
              </thead>
              <tbody className='tablebody'>
                {
                  arr?.map((ddd, i) => {
                    return (
                      <>
                      <tr className='tablerow' key={i} >
                        <td className='tabled'>{ddd?.time} | {ddd?.date}</td>
                        <td className='tabled'>{parseInt(ddd?.flowhalfhour).toLocaleString()} | {parseInt(ddd?.total).toLocaleString()}</td>
                      </tr>
                      </>
                    )
                  })
                }
                      <tr>
                        <th className='tableh tablehead'>Total</th>
                        <td className='tabled tablehead'>{parseInt(flowtotal0)}</td>
                      </tr>
              </tbody>
            </table>
            {/* Table for Inlet-2 */}
            <table className='tablemain' id='tablee'>
              <thead className='tablehead'>
                <tr className='tablerow'>
                  {/* <th className='tableh'>Time | Date</th> */}

                        <th className='tableh flowtotalid'>{array[1]?.machineName} <br /> Flow m3/m | Total m3 <br />{array[1]?.Device_ID}</th>
                </tr>
              </thead>
              <tbody className='tablebody'>
                {
                  arr1?.map((ddd, i) => {
                    return (
                      <>
                      <tr className='tablerow' key={i}>
                        {/* <td className='tabled'>{ddd?.time} | {ddd?.date}</td> */}
                        <td className='tabled'>{parseInt(ddd?.flowhalfhour).toLocaleString()} | {parseInt(ddd?.total).toLocaleString()}</td>
                      </tr>
                      </>
                    )
                  })
                }
                      <tr>
                        <td className='tabled tablehead'>{parseInt(flowtotal1)}</td>
                      </tr>
              </tbody>
            </table>
            {/* Table for Outlet */}
            <table className='tablemain' id='tablee'>
              <thead className='tablehead'>
                <tr className='tablerow'>
                  {/* <th className='tableh'>Time | Date</th> */}
                        <th className='tableh flowtotalid'>{array[2]?.machineName} <br /> Flow m3/m | Total m3 <br />{array[2]?.Device_ID}</th>
                </tr>
              </thead>
              <tbody className='tablebody'>
                {
                  arr2?.map((ddd, i) => {
                    return (
                      <>
                        <tr className='tablerow' key={i}>
                          {/* <td className='tabled'>{ddd?.time} | {ddd?.date}</td> */}
                          <td className='tabled'>{parseInt(ddd?.flowhalfhour).toLocaleString()} | {parseInt(ddd?.total).toLocaleString()}</td>
                        </tr>
                      </>
                    )
                  })
                }
                        <tr>
                        <td className='tabled tablehead'>{parseInt(flowtotal2)}</td>
                      </tr>
              </tbody>
            </table>
                        {/* Table for ETP Waste */}
                        <table className='tablemain' id='tablee'>
              <thead className='tablehead'>
                <tr className='tablerow'>
                  {/* <th className='tableh'>Time | Date</th> */}
                        <th className='tableh flowtotalid'>{array[3]?.machineName} <br /> Flow m3/m | Total m3 <br />{array[3]?.Device_ID}</th>
                </tr>
              </thead>
              <tbody className='tablebody'>
                {
                  arr3?.map((ddd, i) => {
                    return (
                      <>
                        <tr className='tablerow' key={i}>
                          {/* <td className='tabled'>{ddd?.time} | {ddd?.date}</td> */}
                          <td className='tabled'>{parseInt(ddd?.flowhalfhour).toLocaleString()} | {parseInt(ddd?.total).toLocaleString()}</td>
                        </tr>
                      </>
                    )
                  })
                }
                        <tr>
                        <td className='tabled tablehead'>{parseInt(flowtotal3)}</td>
                      </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="btnss">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="excelbtn"
            table="tablee"
            filename={reportformat}
            sheet="tablexls"
            buttonText="Excel" />
          <button className='pdfbtn' onClick={pdfbtn}>Pdf</button>
          <button className='pdfbtn' onClick={close}>Close</button>
        </div>
      </div>
      {/* </div> */}
    </>


  )
}

export default ReportMachine
