import React from 'react'
import './GaugeChart1.css'
import Chart from "react-apexcharts";

export default function GuageChart1(props) {
  const state = {
    options: {
      chart: {
        toolbar: {
          show: false
        }
      },
      series: [props?.val],
      plotOptions: {
        radialBar: {
          // startAngle: -135,
          // endAngle: 225,
          startAngle: -135,
          endAngle: 135,
          hollow: {
            margin: 0,
            size: '60%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: '#fff',
            strokeWidth: '50%',
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -15,
              show: true,
              color: props.textColor,
              fontSize: '12px'
            },
            value: {
              formatter: function (val) {
                return (val) + props.valuegraph;
              },
              color: props.numColor,
              fontSize: '16px',
              offsetY: -5,
              show: true,
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 1,
          gradientToColors: [props.gradientColor],
          // gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: [props.name],
    },
  };


  return (
    <>
      <div id="gaugechart" className='guage'>
  <Chart options={state.options} series={state.options.series} type="radialBar" height={180} width={180}/>
</div>
    </>
  )
}
