import { DateRangePicker } from 'rsuite';
import React, { useState, useEffect } from 'react'
import './MachineData.css'
import { useNavigate, useLocation } from 'react-router-dom'
// import { finddate } from '../../Service/FindByDateApi'
import moment from 'moment'
import Header from '../Common/Header/Header';
import GuageChart1 from '../Common/Charts/GaugeChart1/GuageChart1'
import { GetMeterData } from '../../Service/MeterApi';
import Footer from '../Common/Footer/Footer';
import AreaChart1 from '../Common/Charts/AreaChart1/AreaChart1';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Clock from 'react-live-clock';


const MachineData = () => {

    const [value, setValue] = useState([]);

    let new1 = moment(value[0])?.format("DD-MM-YYYY")
    let new2 = moment(value[1])?.format("DD-MM-YYYY")

    let sdate = moment(value[0])?.format("YYYY-MM-DD")
    let edate = moment(value[1])?.format("YYYY-MM-DD")

    // console.log("epoch date start date-----", value)
    // console.log("epoch date end date-----", edate)
    let sendDate = {
        startDate: new1,
        endDate: new2
    }
    let dateback = {
        startDate: sdate,
        endDate: edate
    }
    // let originalDateBack = {
    //     startDate: value[0],
    //     endDate: value[1]
    // }

    const navigate = useNavigate()
    const location = useLocation()

    let MQTT_ID = new URLSearchParams(location.search).get("MQTT_ID")
    const [meterDashData, setMeterDashData] = useState([])
    // console.log("-------", meterDashData)
    let getarray = meterDashData?.map((array) => { return array?.meterReadings })
    let getarrayhalf = meterDashData?.map((array) => { return array?.meterReadingsHalfHour })




    // this api is goes to get api of meters data
    const gettingMeterData = async () => {
        let res = await GetMeterData()
        // let res = await GetMeterData(MQTT_ID)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            setMeterDashData(res.data)

        }
    }


    useEffect(() => {
        // if (MQTT_ID) {
        // setInterval(() => {
        gettingMeterData()
        // }, 5000);
        // }
    }, [MQTT_ID])

    const settingDate = (event) => {
        if (event == null) {
            setValue([])
        } else {
            setValue(event)
        }
    }

    // the function is use to find the date between start date and last date
    const findByDate = () => {
        let startDate = dateback?.startDate
        let endDate = dateback?.endDate
        let sDate = new Date(startDate).getTime()
        let eDate = new Date(endDate).getTime()
        let epStartDate = new Date(sDate - 18000000).getTime()
        let epEndDate = new Date(eDate + 68000000).getTime()
        let filterDate = meterDashData?.map((meter) => {
            return {
                ...meter,
                meterReadingsHalfHour: meter?.meterReadingsHalfHour.filter((val) => {
                    let currenDate = new Date(val?.datetime).getTime()
                    if (currenDate >= epStartDate && currenDate <= epEndDate) {
                        return true
                    }
                    else{
                        return false
                    }
                })
            }
        })
        // console.log("filter the date in front-end",filterDate)
        // finddate(dispatch, dateback, _groupName)
        // finddate(dispatch, sendDate, MQTT_ID)
        // finddate(dispatch, dateback, MQTT_ID)

        navigate('/dashboard/report', { state: {date: sendDate, filterDate: filterDate } })
    }
    return (
        <>
            <div className="meterdatamain">

                <Header timedate={<Clock format={'HH:mm:ss| DD-MM-YYYY'} ticking={true} timezone={'asia/Karachi'} />}/>
                <div className="machinedatamain">
                    <div className="machinetabledata">
                    </div>
                </div>
                <div className="gatherdateID">
                    <div className="timeID">
                        <div className="time">
                            {getarray[0]?.[getarray[0]?.length - 1]?.time + '\t | \t' + getarray[0]?.[getarray[0]?.length - 1]?.date}
                        </div>
                        <div className="DeviceID">
                            {meterDashData[0]?.Device_ID}
                            {/* {meterDashData[meterDashData.length - 1]?.value?.Device_ID} */}
                        </div>
                    </div>

                    <div className="datepiker">
                        <DateRangePicker showOneCalendar className='rangepiker' onChange={(event) => settingDate(event)} value={value} placeholder="Start Date ~ End Date"
                            renderValue={(value) => {
                                return moment(value[0])?.format("DD-MM-YYYY") + ' ~ ' + moment(value[1])?.format("DD-MM-YYYY");
                            }} />
                        <button className='btnreport' type='submit' onClick={findByDate}> Report</button></div>
                </div>
                <div className="getherCharts">
                    <div className="gaugeall">
                        <div className="guageChartcontainer">
                            <GuageChart1 val={getarray[0]?.[getarray[0]?.length - 1]?.flow} name={meterDashData[0]?.machineName} numColor='rgb(0, 0, 0)' textColor='rgb(41, 128, 185 )' valuegraph=" m3/h" gradientColor='rgba(0, 0, 255, 0.7)' />
                            <div className="totalizermain">
                                <div className="totalname">Total</div>
                                <div className="totalvalue">{parseInt(getarray[0]?.[getarray[1]?.length - 1]?.total).toLocaleString()}m3</div>
                            </div>
                        </div>
                        <div className="guageChartcontainer">
                            <GuageChart1 val={getarray[1]?.[getarray[1]?.length - 1]?.flow} name={meterDashData[1]?.machineName} numColor='rgb(0, 0, 0)' textColor='rgb(41, 128, 185 )' valuegraph="m3/h" gradientColor='rgb(0, 128,0 )' />
                            <div className="totalizermain">
                                <div className="totalname">Total</div>
                                <div className="totalvalue">{parseInt(getarray[1]?.[getarray[1]?.length - 1]?.total).toLocaleString()}m3</div>
                            </div>
                        </div>
                        <div className="guageChartcontainer">
                            <GuageChart1 val={getarray[2]?.[getarray[2]?.length - 1]?.flow} name={meterDashData[2]?.machineName} numColor='rgb(0, 0, 0)' textColor='rgb(41, 128, 185 )' valuegraph="m3/h" gradientColor='rgb(169, 0, 227 )' />
                            <div className="totalizermain">
                                <div className="totalname">Total</div>
                                <div className="totalvalue">{parseInt(getarray[2]?.[getarray[2]?.length - 1]?.total).toLocaleString()}m3</div>
                            </div>
                        </div>
                        <div className="guageChartcontainer">
                            <GuageChart1 val={getarray[3]?.[getarray[3]?.length - 1]?.flow} name={meterDashData[3]?.machineName} numColor='rgb(0, 0, 0)' textColor='rgb(41, 128, 185 )' valuegraph="m3/h" gradientColor='rgb(245, 0, 0 )' />
                            <div className="totalizermain">
                                <div className="totalname">Total</div>
                                <div className="totalvalue">{parseInt(getarray[3]?.[getarray[3]?.length - 1]?.total).toLocaleString()}m3</div>
                            </div>
                        </div>
                    </div>
                    <div className="linechartcontainer">
                        <AreaChart1 flowData={getarrayhalf[0]?.slice(-24)?.map((flow) => flow?.flow)} flowData1={getarrayhalf[1]?.slice(-24)?.map((flow) => flow?.flow)} flowData2={getarrayhalf[2]?.slice(-24)?.map((flow) => flow?.flow)} flowData3={getarrayhalf[3]?.slice(-24)?.map((flow) => flow?.flow)} graphTime={getarrayhalf[0]?.slice(-24)?.map((time) => time?.time)} />
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Footer />
        </>
    )
}
export default MachineData
